<script lang="ts" setup>
import { IStorefrontItem } from '~/types/item'

type RCartAddButtonProps = {
  item: IStorefrontItem
  size?: 'base' | 'large'
  iconOnly?: boolean
  textOnly?: boolean
}

const props = withDefaults(defineProps<RCartAddButtonProps>(), {
  size: 'base',
  iconOnly: false,
})

const { t } = useI18n()
const emit = defineEmits<{ (e: 'redirect'): void }>()
const theme = useStoreTheme()
const cartStore = useCartStore()
const globalStore = useGlobalStore()

const addToCart = (item: IStorefrontItem) => {
  if (item.options?.length) {
    return emit('redirect')
  }
  cartStore.addItem(toCartItem(item, 1, item.baseVariant, globalStore.storeCurrency))
}

const padding = computed(() => {
  if (props.size === 'base') return ['p-3']

  return ['py-4', 'px-6']
})

const isOutOfStock = computed(() => (props.item.quantityAvailable || 0) < 1)
</script>

<template>
  <div class="w-full">
    <button
      v-if="cartStore.isItemInCart(props.item)"
      class="add-to-cart-button flex w-full items-center justify-center gap-2 rounded-full border-1 border-solid border-apple bg-apple text-xs font-medium uppercase text-white md:text-sm"
      :class="padding"
      v-bind="$attrs"
    >
      <RIcon name="ic:round-check" />
      <span v-if="!props.iconOnly">{{ t('components.addToCartButton.added') }}</span>
    </button>

    <button
      v-else-if="isOutOfStock"
      class="add-to-cart-button flex w-full items-center justify-center gap-2 rounded-full border-1 border-solid bg-gray-200 text-xs font-medium uppercase text-gray-300 md:text-sm"
      :class="padding"
      disabled
      v-bind="$attrs"
    >
      <RIcon v-if="!props.textOnly">
        <IconBag />
      </RIcon>
      <span v-if="!props.iconOnly">{{ t('components.addToCartButton.outOfStock') }}</span>
    </button>

    <button
      v-else
      class="add-to-cart-button flex w-full items-center justify-center gap-2 rounded-full border-1 border-solid text-[12px] font-medium uppercase text-white md:text-sm"
      :class="padding"
      :style="{ borderColor: theme.primary, backgroundColor: theme.primary, color: theme.text }"
      v-bind="$attrs"
      @click="addToCart(props.item)"
    >
      <RIcon>
        <IconBag />
      </RIcon>
      <span v-if="!props.iconOnly">{{ t('components.addToCartButton.add') }}</span>
    </button>
  </div>
</template>

<style scoped>
:deep(.r-icon) {
  svg {
    width: 22px;
    height: 22px;

    @media screen and (max-width: 640px) {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
