<script setup lang="ts">
const props = defineProps<{
  title?: string
  showPrice?: boolean
  items: IStorefrontItem[]
}>()

const emit = defineEmits<{
  (e: 'view-product', item: IStorefrontItem): void
  (e: 'add-product', item: IStorefrontItem): void
}>()

const theme = useStoreTheme()
const { isTabletPortraitDown } = useScreenSize()

const sortedItems = computed(() =>
  [...props.items].sort(
    (a, b) => a?.spotlightPreferences?.position - b?.spotlightPreferences?.position,
  ),
)
</script>

<template>
  <div
    id="products-spotlight"
    class="relative -left-4 flex w-screen flex-col gap-6 sm:static sm:w-full sm:gap-10"
  >
    <div
      v-if="props.title"
      class="text-center text-xl font-bold leading-10 lg:text-[32px]"
      :style="{ color: theme.primary }"
    >
      {{ title }}
    </div>
    <div
      class="flex items-stretch justify-between justify-items-center gap-4 overflow-x-auto pb-6 sm:pb-10 lg:gap-8"
    >
      <RProductSpotlightItem
        v-for="item in sortedItems"
        :key="item.id"
        :item="item"
        :show-price="props.showPrice"
        :class="{
          'first:ml-4 last:mr-4': isTabletPortraitDown,
          'first:ml-auto last:mr-auto': !isTabletPortraitDown,
        }"
        @view-product="emit('view-product', item)"
      />
    </div>
  </div>
</template>
