<script setup lang="ts">
import RIcon from '@/components/base/RIcon.vue'
import RSelectField, { SelectOption } from '@/components/base/RSelectField.vue'
import RSelectPopover from '@/components/base/RSelectPopover.vue'

const { t } = useI18n()
const selectedSort = ref('')
const isMobile = useIsMobile()
const itemsStore = useItemsStore()
const selectFieldRef = ref<InstanceType<typeof RSelectField>>()
const selectPopoverRef = ref<InstanceType<typeof RSelectPopover>>()

const sortOptions = computed(() => [
  {
    label: t('components.productListSort.options.priceAsc.label'),
    value: 'price:asc',
    isDisabled: false,
  },
  {
    label: t('components.productListSort.options.priceDesc.label'),
    value: 'price:desc',
    isDisabled: false,
  },
  {
    label: t('components.productListSort.options.nameAsc.label'),
    value: 'title:asc',
    isDisabled: false,
  },
  {
    label: t('components.productListSort.options.nameDesc.label'),
    value: 'title:desc',
    isDisabled: false,
  },
])

const renderSelection = (option?: SelectOption) => {
  const label = option?.label || t('globals.sort')
  return h('div', { class: 'flex items-center gap-1 text--primary' }, [
    h(RIcon, { size: '20px', name: 'tabler:arrows-sort' }),
    h('span', {}, { default: () => label }),
  ])
}

const renderSelectionDesktop = (option?: SelectOption) => {
  const label = option?.label || t('globals.sort')
  return h(
    'div',
    {
      class:
        'flex items-center min-w-[227px] gap-1 bg-white text-sm h-12 text--primary px-4 py-[14px] rounded-[24px]',
    },
    [
      h(RIcon, { size: '20px', name: 'tabler:arrows-sort' }),
      h('span', {}, { default: () => label }),
    ],
  )
}

const updateSort = (value: string) => {
  const [attribute, direction] = value.split(':')
  itemsStore.setSort({ attribute, direction: direction as 'asc' | 'desc' })
}

const resetSort = () => {
  selectedSort.value = ''
  selectFieldRef.value?.close()
  selectPopoverRef.value?.close()
  itemsStore.setSort({ attribute: null, direction: null })
}

onMounted(() => {
  if (itemsStore.sortBy && itemsStore.order) {
    selectedSort.value = `${itemsStore.sortBy}:${itemsStore.order}`
  }
})
</script>

<template>
  <RSelectField
    v-if="isMobile"
    id="sort-select"
    ref="selectFieldRef"
    v-model="selectedSort"
    :is-input="false"
    :options="sortOptions"
    :render-selection="renderSelection"
    :mobile-label="t('globals.sort')"
    :chevron-icon="false"
    @update:model-value="updateSort"
  >
    <template #drawer-footer>
      <button
        class="text--primary h-5 w-full text-center !text-sm"
        color="none"
        link
        @click="resetSort"
      >
        {{ t('globals.resetSort') }}
      </button>
    </template>
  </RSelectField>
  <div v-else>
    <RSelectPopover
      ref="selectPopoverRef"
      v-model="selectedSort"
      :options="sortOptions"
      :render-selection="renderSelectionDesktop"
      @update:model-value="updateSort"
    >
      <template #footer>
        <button
          class="text--primary w-full self-center py-1 !text-sm !font-semibold"
          color="none"
          link
          @click="resetSort"
        >
          {{ t('globals.resetSort') }}
        </button>
      </template>
    </RSelectPopover>
  </div>
</template>
