<script setup lang="ts">
import { useFlag } from '@unleash/proxy-client-vue'
import type { IStorefrontItem } from '~/types/item'

import RProductCellGrid from '~/components/product/RProductCellGrid.vue'
import RProductCellList from '~/components/product/RProductCellList.vue'

const props = defineProps<{ isLoading: boolean }>()

defineEmits(['next-page'])

const router = useRouter()
const store = useItemsStore()
const isMobile = useIsMobile()
const itemStore = useItemStore()
const localePath = useLocalePath()
const globalStore = useGlobalStore()
const isSortingFeatureEnabled = useFlag('storefront.sorting')

const normalizeItemTitle = (itemName: string) => {
  return itemName.replace(/[^a-zA-Z0-9\s]/gim, '').replace(/\s/gim, '-')
}

const handleProductView = (item: IStorefrontItem) => {
  itemStore.currentItem = item
  router.push(
    localePath({
      name: 'products-id-title',
      params: {
        id: item.id,
        title: normalizeItemTitle(item.baseVariant.title ?? ''),
      },
    }),
  )
}

const isGrid = computed(() => {
  return !isMobile.value || globalStore.config.mobileProductCellVariant === 'grid'
})

const productCell = computed(() => {
  return isGrid.value ? RProductCellGrid : RProductCellList
})
</script>

<template>
  <div>
    <RInfiniteScroll
      v-if="store.items.length"
      :total-records="store.totalRecords"
      :current-page="store.currentPage"
      :records-per-page="store.perPage"
      @next-page="$emit('next-page')"
    >
      <div
        v-if="isMobile"
        class="mb-3 mt-4 flex items-end items-center justify-end"
        :class="{ 'justify-between': isSortingFeatureEnabled }"
      >
        <RProductListSort v-if="isSortingFeatureEnabled" />

        <RProductListDisplayToggle />
      </div>

      <div
        data-testid="products-list"
        class=""
        :class="{
          'grid auto-rows-fr grid-cols-1 gap-2 xs:grid-cols-2 sm:grid-cols-4 lg:gap-6': isGrid,
          'flex flex-col gap-2': !isGrid,
        }"
      >
        <component
          :is="productCell"
          v-for="item in store.items"
          :key="item?.id"
          :item="item"
          :data-testid="`product-${item?.id}`"
          @view-product="handleProductView"
        />
      </div>
    </RInfiniteScroll>

    <RSpinner v-if="isLoading && store.items.length > 0" class="mt-4" />

    <div
      v-if="store.items.length === 0 && !props.isLoading"
      class="flex h-60 items-center justify-center text-center text-sm font-semibold text-mine-shaft"
    >
      <p class="w-48">{{ $t('pages.productIndex.emptyState') }}</p>
    </div>
  </div>
</template>
