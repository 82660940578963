<script setup lang="ts">
import { promiseTimeout, useElementBounding } from '@vueuse/core'

const props = defineProps<{
  disabled?: boolean
  showIcon?: boolean
  selectedChildren: Facet | null
  contentClass?: string
}>()
const elRef = ref()
const show = ref(false)
const contentRef = ref()
const theme = useStoreTheme()
const contentBounding = useElementBounding(contentRef)

const isOffScreen = computed(() => {
  return contentBounding.right.value > window.innerWidth - 50
})

const handleShow = async () => {
  if (props.disabled) {
    show.value = false
    return
  }

  if (show.value) {
    show.value = false
    return
  }

  await promiseTimeout(100)
  show.value = true
}

const clickOutside = (e: MouseEvent) => {
  const clickedElement = e.target as HTMLElement

  if (clickedElement.contains(elRef.value)) {
    return
  }

  show.value = false
}

onMounted(() => {
  if (props.disabled) return

  watch(
    () => props.selectedChildren,
    () => {
      show.value = false
    },
  )
})

watch(show, (value) => {
  if (value) {
    document.body.addEventListener('click', clickOutside)
  } else {
    document.body.removeEventListener('click', clickOutside)
  }
})
</script>
<template>
  <div class="popover relative" :class="{ open: show }">
    <div class="trigger relative">
      <button @click="handleShow">
        <RTransition name="slide-down-fade">
          <span v-if="show" class="absolute bottom-[-7px] left-0 z-10 h-8 w-full bg-white" />
        </RTransition>

        <div
          class="trigger relative z-20 rounded-3xl"
          :style="{ color: theme.primary }"
          :class="[show ? 'shadow-top-sm' : 'shadow-sm', { 'is-show': show }]"
        >
          <slot name="trigger" />
          <RIcon
            v-if="props.showIcon"
            size="20"
            class="absolute right-2 top-2/4 mt-[1px] translate-y-[-50%] transition-transform duration-300"
            :class="[show ? '-rotate-180' : 'rotate-0']"
            name="ic:expand-more"
          />
        </div>
      </button>
      <RTransition name="slide-down-fade">
        <span
          v-if="show"
          ref="elRef"
          class="absolute bottom-[-7px] z-10 shadow-2xl"
          :class="[isOffScreen ? 'left-[-27px]' : 'right-[-29px]']"
          @click.stop
        >
          <svg
            v-if="!isOffScreen"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.638113 0.000325431L1 30.0003H29.7095V29.9917C13.9864 29.7594 0.790625 16.4477 0.638113 0.000325431Z"
              fill="white"
            />
          </svg>
          <svg
            v-else
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_306_2)">
              <path
                d="M29.3619 0.000274658L29 30.0002L0.290501 30.0003L0.290501 29.9917C16.0136 29.7593 29.2094 16.4476 29.3619 0.000274658Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_306_2">
                <rect
                  width="30"
                  height="30"
                  fill="white"
                  transform="matrix(-1 8.74228e-08 8.74228e-08 1 30 0)"
                />
              </clipPath>
            </defs>
          </svg>
        </span>
      </RTransition>
    </div>
    <RTransition name="slide-down-fade">
      <div
        v-if="show"
        ref="contentRef"
        class="absolute z-30 rounded-b-2xl bg-white p-4 shadow-2xl"
        :class="[
          isOffScreen ? 'right-0 top-[47px] rounded-s-2xl ' : 'left-0 top-[45px] rounded-e-2xl ',
          props.contentClass,
        ]"
        @click.stop
      >
        <div class="h-full overflow-y-auto">
          <slot />
        </div>
      </div>
    </RTransition>
  </div>
</template>
<style>
.trigger.is-show > button {
  border-color: transparent !important;
}
</style>
