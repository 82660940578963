<script lang="ts" setup>
import { promiseTimeout } from '@vueuse/core'
import { Facet } from '~/types/api'
import theme from '@/tailwind.config.cjs'

const emit = defineEmits(['fetch'])

const { t } = useI18n()
const isMobile = useIsMobile()
const itemsStore = useItemsStore()
const modalState = useModalState()
const activeCategory = ref<Facet | null>(null)
const selectedCategory = ref<Facet | null>(null)
const selectedSubcategory = ref<Facet | null>(null)

const handleTogglePromotionsFilter = () => {
  if (itemsStore.filterByPromotion) return

  selectedCategory.value = null
  selectedSubcategory.value = null

  itemsStore.facetBy = null
  itemsStore.clearOnSearch()
  itemsStore.filterByPromotion = true
  emit('fetch')
}

const isCategoryActive = (facet: Facet) => {
  return selectedCategory.value?.id === facet.id
}

const handleSelectParent = async (facet: Facet | null) => {
  if (selectedCategory.value === null && facet === null && !itemsStore.filterByPromotion) return

  itemsStore.filterByPromotion = false

  if (facet?.hasChildren) {
    activeCategory.value = facet
    await promiseTimeout(100)
    modalState.open()
    return
  }

  if (facet && facet.id === selectedCategory.value?.id) return

  selectedCategory.value = facet
  selectedSubcategory.value = facet

  itemsStore.clearOnSearch()
  itemsStore.facetBy = facet
  itemsStore.term = facet?.value || undefined
  emit('fetch')
}

const handleSelectChildren = (facet: Facet) => {
  if (facet.id === selectedSubcategory.value?.id) return

  selectedCategory.value = facet?.parent || facet
  selectedSubcategory.value = facet

  itemsStore.clearOnSearch()
  itemsStore.facetBy = facet
  itemsStore.term = facet?.value || undefined

  if (facet.parent) {
    itemsStore.term = `${facet.parent.value}_${facet?.value}`
  } else {
    itemsStore.term = facet?.value || undefined
  }

  modalState.close()
  emit('fetch')
}

watch(
  () => itemsStore.term,
  (term) => {
    if (!term) {
      activeCategory.value = null
      selectedCategory.value = null
      selectedSubcategory.value = null
    }
  },
)

onMounted(async () => {
  const term = itemsStore.term
  itemsStore.term = undefined

  await itemsStore.loadFacetsV2()

  if (!term) return

  const [categoryName, subcategoryName] = term.split('_')

  if (!categoryName && !subcategoryName) return

  const facet = itemsStore.facets.find((facet) => {
    if (!subcategoryName)
      return facet.value === categoryName || facet.parent?.value === categoryName

    return subcategoryName === facet.value && categoryName === facet.parent?.value
  })

  if (!facet) {
    emit('fetch')
    return
  }

  if (subcategoryName) {
    handleSelectChildren(facet)
    return
  }

  handleSelectParent(facet.parent || facet)
})

const handleClickBreadcrumb = (name: string) => {
  if (name === selectedCategory.value?.value) {
    return handleSelectChildren(selectedCategory.value)
  }
  handleSelectParent(null)
}
</script>

<template>
  <div
    v-if="itemsStore.facets.length || itemsStore.hasPromotions"
    class="flex gap-2 overflow-x-auto py-1 md:flex-wrap md:justify-center md:overflow-x-visible"
  >
    <RLabel
      v-if="itemsStore.hasPromotions"
      class="shadow-sm"
      :active="itemsStore.filterByPromotion"
      :custom-active-color="theme.theme.extend.colors.red[300]"
      @click="handleTogglePromotionsFilter"
    >
      <span class="flex items-center gap-1">
        <RIcon size="18" name="ic:round-local-offer" />
        {{ t('globals.promotions.title') }}
      </span>
    </RLabel>

    <RLabel
      :active="!selectedCategory && !itemsStore.filterByPromotion"
      class="shadow-sm"
      @click="handleSelectParent(null)"
    >
      {{ t('globals.all') }}
    </RLabel>
    <RProductFacetsPopover
      v-for="facet in itemsStore.sortedParents"
      :key="facet.value"
      :selected-children="selectedSubcategory"
      :show-icon="!!facet.hasChildren && !isMobile"
      :disabled="isMobile || !facet.hasChildren"
      :content-class="
        isMobile
          ? 'w-full'
          : itemsStore.childrenFacets(facet.id).length === 1
            ? 'min-w-[220px]'
            : 'min-w-[450px]'
      "
    >
      <template #trigger>
        <RLabel
          :active="isCategoryActive(facet)"
          :class="{ 'md:pr-6': facet.hasChildren }"
          @click="handleSelectParent(facet)"
        >
          <div
            class="flex items-center justify-center gap-1"
            :class="{ 'mr-1': facet.hasChildren && !isMobile }"
          >
            {{ facet.value }}
          </div>
        </RLabel>
      </template>
      <RProductFacetsList
        :list="itemsStore.childrenFacets(facet.id)"
        :selected-children="selectedSubcategory"
        :active-parent="activeCategory"
        @select="handleSelectChildren"
      />
    </RProductFacetsPopover>
    <RProductFacetsDrawer
      v-if="isMobile"
      v-model:show="modalState.isOpen.value"
      :active-parent="activeCategory"
      :list="activeCategory ? itemsStore.childrenFacets(activeCategory.id) : []"
      @view-all="handleSelectChildren(activeCategory!)"
    >
      <RProductFacetsList
        v-if="activeCategory"
        :list="itemsStore.childrenFacets(activeCategory.id)"
        :active-parent="activeCategory"
        :selected-children="selectedSubcategory"
        @select="handleSelectChildren"
      />
    </RProductFacetsDrawer>
  </div>
  <RBreadcrumb
    v-if="itemsStore.facetsValueTree.length"
    :items="[t('globals.homePage'), ...itemsStore.facetsValueTree]"
    @click="handleClickBreadcrumb"
  />
</template>
