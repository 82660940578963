<script setup lang="ts">
import { PromotionType } from '@rediredi/types'
import { FALLBACK_IMAGE_PATH } from '~/consts/images'
import { IStorefrontItem } from '~/types/item'

const props = defineProps<{
  item: IStorefrontItem
  showPrice?: boolean
}>()

const emit = defineEmits<{
  (e: 'view-product', item: IStorefrontItem): void
  (e: 'add-product', item: IStorefrontItem): void
}>()

const { event } = useEvent()
const globalStore = useGlobalStore()
const { isTabletPortraitDown } = useScreenSize()

const isDiscountsEnabled = true

const handleClick = () => {
  emit('view-product', props.item)
  event('select_item', {
    item_list_id: 'main_search',
    item_list_name: 'Main search',
    items: [itemToAnalytics(props.item)],
  })
}

const currency = globalStore.storeCurrency

const oldPrice = computed(() => {
  if (!props.item.promotion?.showBeforeAndAfterPrice || !isDiscountsEnabled) return null

  return props.item.baseVariant.price
})
const price = computed(() => {
  const price =
    props.item.promotion && props.item.promotion.type !== PromotionType.Label && isDiscountsEnabled
      ? props.item.baseVariant.promotionPrice
      : props.item.baseVariant.price
  return price
})

const imgSrc = computed(() => {
  const pictures = getCoverVariantPictures(props.item)
  if (pictures.length) return optimizedImage(getPictureUrl(pictures[0]), 'small')
  return FALLBACK_IMAGE_PATH
})

const isOutOfStock = computed(() => (props.item.quantityAvailable || 0) < 1)
</script>

<template>
  <div
    class="relative flex max-w-[380px] flex-1 cursor-pointer flex-col justify-between overflow-hidden rounded-3xl border-1 border-solid border-mercury bg-white p-3 transition-all duration-300 hover:shadow-xl lg:p-5"
    :class="{ 'min-w-[278px]': isTabletPortraitDown }"
  >
    <RProductIndicator
      v-if="isOutOfStock || props.item.promotion"
      :type="isOutOfStock ? 'outOfStock' : 'promotion'"
      :promotion="props.item.promotion"
      context="grid"
    />

    <div role="button" class="h-full" @click="handleClick">
      <img
        alt=""
        loading="lazy"
        class="flex aspect-square w-full justify-center rounded-lg object-contain align-middle"
        :class="{
          'p-8 sm:p-16': imgSrc === FALLBACK_IMAGE_PATH,
        }"
        :src="imgSrc"
      />

      <div
        class="flex gap-2 px-0 pt-4"
        :class="{
          'flex-row items-center': !props.showPrice,
          'flex-col': props.showPrice,
        }"
      >
        <p
          class="min-h-12 line-clamp-2 w-full overflow-hidden text-ellipsis text-center text-sm font-medium uppercase text-gray-400 lg:text-base"
        >
          {{ item.baseVariant.title }}
        </p>

        <div class="flex items-center justify-center">
          <div
            v-if="props.showPrice"
            class="min-h-11 lg:min-h-12 flex flex-1 flex-col justify-center self-center text-center text-gray-400"
          >
            <template v-if="price?.amount">
              <template v-if="oldPrice">
                <div
                  class="h-5 w-full text-[12px] font-medium !leading-6 text-gray-400 line-through lg:h-6 lg:text-sm"
                >
                  <span>{{ formatPriceInParts(oldPrice.amount, { currency }).currency }}</span>
                  <span>{{ formatPriceInParts(oldPrice.amount, { currency }).amount }}</span>
                </div>
              </template>
              <div
                v-else
                class="h-5 w-full text-[12px] font-medium !leading-6 text-gray-400 line-through lg:h-6 lg:text-sm"
              >
                &nbsp;
              </div>
              <div class="h-6 w-full self-end text-xl font-extrabold !leading-6 lg:text-2xl">
                <span>{{ formatPriceInParts(price.amount, { currency }).currency }}</span>
                <span>{{ formatPriceInParts(price.amount, { currency }).amount }}</span>
              </div>
            </template>
            <template v-else>
              <div
                class="h-5 w-full text-[12px] font-medium !leading-6 text-gray-400 line-through lg:h-6 lg:text-sm"
              >
                &nbsp;
              </div>
              <div class="h-6 w-full self-end text-xl font-extrabold !leading-6 lg:text-2xl">
                {{ $t('globals.noPrice') }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
