<script setup lang="ts">
const props = defineProps<{
  list: Facet[]
  selectedChildren: Facet | null
  activeParent: Facet | null
}>()
const emit = defineEmits<{
  (e: 'select', facet: Facet): void
}>()

const isMobile = useIsMobile()
</script>
<template>
  <RBaseButton
    v-if="!isMobile"
    :color="
      props.activeParent && props.selectedChildren?.id === props.activeParent?.id
        ? 'primary'
        : 'none'
    "
    class="btn !rounded-[10px] px-2 text-sm text-gray-300 transition-colors duration-300 md:mb-3 md:whitespace-nowrap"
    :class="{
      selected: props.selectedChildren?.id === props.activeParent?.id,
      'h-9': !isMobile,
      'h-10': isMobile,
    }"
    :full-width="false"
    @click="emit('select', props.activeParent!)"
  >
    {{ $t('pages.productIndex.categories.seeAllProducts') }}
  </RBaseButton>
  <ul
    class="list grid list-none grid-cols-1 gap-x-3 gap-y-2 overflow-x-hidden text-left"
    :class="{ '!grid-cols-2': props.list.length > 1 && !isMobile }"
  >
    <li v-for="item in props.list" :key="item.id">
      <button
        :color="props.selectedChildren?.id === item.id ? 'primary' : 'none'"
        class="btn flex w-full items-center justify-start !rounded-[10px] px-3 py-1 text-left text-sm text-gray-400 transition-colors duration-300 md:whitespace-nowrap"
        :class="{
          'selected background--primary text-white': props.selectedChildren?.id === item.id,
          'bg-gray-100': props.selectedChildren?.id !== item.id,
          '!h-9': !isMobile,
          '!h-10': isMobile,
        }"
        :full-width="true"
        @click="emit('select', item)"
      >
        {{ item.value }}
      </button>
    </li>
  </ul>
</template>
<style scoped>
.btn {
  gap: 8px;
}
.btn:hover:not(.selected) {
  color: var(--color-primary);
}
</style>
