<script setup lang="ts">
import { promiseTimeout, useElementBounding } from '@vueuse/core'
import { OptionValue, SelectOption } from './RSelectField.vue'

const props = defineProps<{
  modelValue?: OptionValue
  options: SelectOption[]
  renderSelection?: (option?: SelectOption) => Component
}>()

const emit = defineEmits(['update:modelValue'])

const show = ref(false)
const contentRef = ref()
const slots = useSlots()
const contentBounding = useElementBounding(contentRef)

const selectedOption = computed(() =>
  props.options.find((option) => option.value === props.modelValue),
)

const isOffScreen = computed(() => {
  return contentBounding.right.value > window.innerWidth - 50
})

const handleShow = async () => {
  if (show.value) {
    close()
    return
  }

  await promiseTimeout(100)
  show.value = true
}

const handleSelect = (value: OptionValue) => {
  emit('update:modelValue', value)
  show.value = false
}

watch(show, (isShowing) => {
  if (isShowing) {
    document.body.addEventListener('click', close)
  } else {
    document.body.removeEventListener('click', close)
  }
})

const close = () => {
  show.value = false
}

defineExpose({
  close,
})
</script>
<template>
  <div class="popover relative" :class="{ open: show }">
    <div class="trigger relative">
      <button @click="handleShow">
        <RTransition name="slide-down-fade">
          <span v-if="show" class="absolute bottom-[-7px] left-0 z-10 h-8 w-full bg-white" />
        </RTransition>

        <div class="trigger relative z-20 rounded-3xl">
          <div v-if="renderSelection">
            <component :is="renderSelection(selectedOption)" />
          </div>
        </div>
      </button>
    </div>
    <RTransition name="slide-down-fade">
      <div
        v-if="show"
        ref="contentRef"
        class="absolute z-30 min-w-[220px] rounded-b-2xl bg-white p-3 pt-2 shadow-md"
        :class="[
          isOffScreen ? 'right-0 top-[47px] rounded-s-2xl ' : 'left-0 top-[45px] rounded-e-2xl ',
        ]"
        @click.stop
      >
        <div class="flex h-full flex-col gap-2 overflow-y-auto">
          <div
            v-for="option in props.options"
            :key="option.value.toString()"
            role="button"
            class="flex h-10 cursor-pointer items-center rounded-lg px-3 text-sm"
            :class="{
              'background--primary text-white': option.value === modelValue,
              'bg-gray-100 text-gray-400': option.value !== modelValue,
            }"
            @click="handleSelect(option.value)"
          >
            {{ option.label }}
          </div>
        </div>

        <div v-if="slots.footer">
          <div class="my-2 h-[1px] w-full bg-mercury opacity-60"></div>
          <slot name="footer" />
        </div>
      </div>
    </RTransition>
  </div>
</template>
