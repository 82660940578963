<script setup lang="ts">
import { useFlag } from '@unleash/proxy-client-vue'
import { useDebounceFn } from '@vueuse/core'

const emit = defineEmits(['fetch'])

const searchInputRef = ref()
const isMobile = useIsMobile()
const itemsStore = useItemsStore()
const { event, metaEvent } = useEvent()
const isSortingFeatureEnabled = useFlag('storefront.sorting')

const blur = () => {
  searchInputRef.value.el.blur()
}
const debouncedUpdate = useDebounceFn((val: string) => {
  itemsStore.clearOnSearch()
  itemsStore.facetBy = null
  itemsStore.term = undefined
  itemsStore.query = val || undefined
  event('search', {
    search_term: val,
  })
  metaEvent('Search', { search_string: val })
  emit('fetch')
}, 500)

const clear = () => {
  itemsStore.clearOnSearch()
  itemsStore.query = undefined
  emit('fetch')
}
</script>

<template>
  <div data-testid="product-filters">
    <div
      class="m-auto mb-0 flex w-full flex-col gap-4 sm:mb-6 md:gap-6"
      :class="{ 'mb-4 md:mb-6': !itemsStore.facets.length && !itemsStore.hasPromotions }"
    >
      <div
        v-if="itemsStore.totalRecords && itemsStore.totalRecords > 0"
        class="relative flex w-full items-center justify-center gap-2.5 self-center"
      >
        <RInput
          ref="searchInputRef"
          :model-value="itemsStore.query || ''"
          :input-props="{
            type: 'search',
            enterKeyHint: 'go',
            placeholder: $t('globals.search'),
            class: 'search-input',
          }"
          class="self-center"
          :class="{ 'min-w-[552px]': isSortingFeatureEnabled && !isMobile, 'w-full': isMobile }"
          input-class="h-12"
          clearable
          @enter-press="blur"
          @update:model-value="debouncedUpdate"
          @clear="clear"
        >
          <template #appended>
            <RIcon size="26" name="ic:baseline-search" />
          </template>
        </RInput>

        <RProductListSort v-if="isSortingFeatureEnabled && !isMobile" />
      </div>

      <RProductFacets data-testid="facets" @fetch="emit('fetch')" />
    </div>
  </div>
</template>
