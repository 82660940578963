<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'

const route = useRoute()
const router = useRouter()
const store = useItemsStore()
const isMobile = useIsMobile()
const itemStore = useItemStore()
const localePath = useLocalePath()
const itemsStore = useItemsStore()
const currentStore = useCurrentStore()
const categoryQuery = useRouteQuery('c')
const isLoading = ref(!!categoryQuery.value)

const normalizeItemTitle = (itemName: string) => {
  return itemName.replace(/[^a-zA-Z0-9\s]/gim, '').replace(/\s/gim, '-')
}

const isProductSpotlightVisible = computed(() => {
  return currentStore.storefrontPreferences?.spotlight?.enabled && store.spotlight.length > 0
})

const handleProductView = (item: IStorefrontItem) => {
  itemStore.currentItem = item
  router.push(
    localePath({
      name: 'products-id-title',
      params: {
        id: item.id,
        title: normalizeItemTitle(item.baseVariant.title ?? ''),
      },
    }),
  )
}

const fetchItems = async () => {
  isLoading.value = true
  const { data } = await itemsStore.searchItems()

  if (!store.hasInitiallyLoaded) {
    store.hasInitiallyLoaded = true
  }

  store.items.push(...data)

  isLoading.value = false
}

const fetchSpotlightItems = async () => {
  isLoading.value = true
  const { data } = await itemsStore.getSpotlightItems()

  if (!data?.length) return
  store.spotlight = data.slice(0, 4)
}

const nextPage = () => {
  itemsStore.currentPage = itemsStore.currentPage + 1
  fetchItems()
}

onBeforeRouteLeave(() => {
  store.scrollPosition = window.scrollY
})

onMounted(() => {
  window.scrollTo({
    top: store.scrollPosition,
    behavior: 'smooth',
  })

  fetchSpotlightItems()
})

if (!itemsStore.items?.length && currentStore?.id && !categoryQuery.value) {
  let promotionPromise

  if (route.query.promoId) {
    promotionPromise = itemsStore.loadPromotion()
  }

  const itemsPromise = fetchItems()

  Promise.all([itemsPromise, promotionPromise])
}
</script>

<template>
  <div
    class="relative"
    :style="{
      background: isMobile
        ? 'transparent'
        : `linear-gradient(180deg, ${currentStore.themeColorScheme?.primary}24 10vh, #f3f3f3cc 55vh, #f3f3f3 25%, #f3f3f3 100%)`,
    }"
  >
    <RAppStoreBanner id="store-banner" />

    <div
      class="mx-auto -mt-2 max-w-[1280px] rounded-ee-lg rounded-es-lg p-4 pt-7 sm:-mt-8 sm:px-10 sm:pb-10 sm:pt-16"
    >
      <div class="flex flex-col gap-0">
        <RProductSpotlight
          v-if="isProductSpotlightVisible"
          :title="currentStore.storefrontPreferences?.spotlight?.title"
          :show-price="currentStore.storefrontPreferences?.spotlight?.showPrices"
          :items="itemsStore.spotlight"
          @view-product="handleProductView"
        />
        <ClientOnly>
          <RProductFilters :class="{ 'pt-6': !isProductSpotlightVisible }" @fetch="fetchItems" />
        </ClientOnly>
      </div>

      <RProductZeroState v-if="itemsStore.totalRecords === 0" />

      <RSpinner v-if="isLoading && store.items.length === 0" class="h-60" />

      <RProductList v-else :is-loading="isLoading" @next-page="nextPage" />
    </div>
  </div>
</template>
