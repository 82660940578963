<script setup lang="ts">
const props = defineProps<{ activeParent: Facet | null; list: Facet[] }>()
const emit = defineEmits<{
  (e: 'view-all'): void
}>()

const style = computed(() => {
  const initialHeight = 140
  const gaps = (props.list.length - 1) * 8
  const elements = props.list.length * 40
  const total = initialHeight + gaps + elements
  const screenHeight = window.innerHeight

  if (total > screenHeight * 0.6) {
    return {
      height: `${screenHeight * 0.6}px`,
    }
  }

  return {
    height: `${initialHeight + gaps + elements}px`,
  }
})
</script>
<template>
  <RBaseDrawer
    v-bind="$attrs"
    placement="bottom"
    with-backdrop
    :style="style"
    text-class="text--primary !text-base"
    :title="props.activeParent?.value || ''"
  >
    <div class="h-[calc(100%-92px)] overflow-y-auto">
      <slot />
    </div>

    <template #drawer-footer>
      <div class="my-4 h-[1px] w-full bg-gray-100"></div>
      <button
        link
        class="btn text--primary h-5 w-full text-center text-sm"
        color="primary"
        full-width
        @click="emit('view-all')"
      >
        {{ $t('pages.productIndex.categories.seeAllProducts') }}
      </button>
    </template>
  </RBaseDrawer>
</template>
